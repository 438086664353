import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Overlay from '@/components/common/Overlay.vue';
import UserAvatar from '@/components/layout/UserAvatar.vue';
import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
import { mapState } from 'vuex';
var UploadAvatar = (function (_super) {
    __extends(UploadAvatar, _super);
    function UploadAvatar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.file = null;
        _this.loading = false;
        _this.errorMessage = '';
        return _this;
    }
    UploadAvatar.prototype.uploadAvatar = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, photoURL, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('uploadAvatar -> this.file', this.file);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        this.loading = true;
                        return [4, this.storageRef().put(this.file)];
                    case 2:
                        data = _a.sent();
                        return [4, this.storageRef().getDownloadURL()];
                    case 3:
                        photoURL = _a.sent();
                        console.log(data);
                        this.$auth.currentUser.updateProfile({ photoURL: photoURL });
                        this.$store.commit('setAvatar', photoURL);
                        this.$emit('hide-modal');
                        return [3, 6];
                    case 4:
                        error_1 = _a.sent();
                        console.log(error_1);
                        this.errorMessage = 'Not authorized';
                        return [3, 6];
                    case 5:
                        this.loading = false;
                        return [7];
                    case 6: return [2];
                }
            });
        });
    };
    UploadAvatar.prototype.storageRef = function () {
        return this.$storage.ref("/users/" + this.uid + "/" + this.fileName);
    };
    UploadAvatar.prototype.validateUpload = function () {
        var file = this.file;
        if (!file)
            return (this.errorMessage = 'You must provide a file.');
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            return (this.errorMessage = 'You must provide a valid jpg/png file.');
        }
        this.uploadAvatar();
    };
    Object.defineProperty(UploadAvatar.prototype, "fileName", {
        get: function () {
            return 'profilePicture.' + this.file.name.substr(-3).toLowerCase() || '';
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], UploadAvatar.prototype, "show", void 0);
    UploadAvatar = __decorate([
        Component({
            components: { Overlay: Overlay, UserAvatar: UserAvatar, LoadingOverlay: LoadingOverlay },
            computed: mapState({
                uid: function (_a) {
                    var user = _a.user;
                    return user.uid;
                }
            })
        })
    ], UploadAvatar);
    return UploadAvatar;
}(Vue));
export default UploadAvatar;

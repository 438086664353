import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import UserAvatar from '../layout/UserAvatar.vue';
import UploadAvatar from './UserDetails/UploadAvatar.vue';
var UserDetails = (function (_super) {
    __extends(UserDetails, _super);
    function UserDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showModal = false;
        return _this;
    }
    UserDetails = __decorate([
        Component({
            components: { UploadAvatar: UploadAvatar, UserAvatar: UserAvatar }
        })
    ], UserDetails);
    return UserDetails;
}(Vue));
export default UserDetails;

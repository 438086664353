var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VCard",
    { staticClass: "UserDetails" },
    [
      _c("div", { staticClass: "UserDetails-profile" }, [
        _c(
          "div",
          { staticClass: "UserDetails-profile-data" },
          [
            _c("UserAvatar", { attrs: { size: 190 } }),
            _c(
              "div",
              { staticClass: "icon-container" },
              [
                _c(
                  "VIcon",
                  {
                    staticClass: "icon clickable",
                    on: {
                      click: function($event) {
                        _vm.showModal = true
                      }
                    }
                  },
                  [_vm._v("mdi-camera")]
                )
              ],
              1
            ),
            _c("h6", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.$store.getters.userNick))
            ]),
            _c("span", { staticClass: "rol" }, [_vm._v("BIM Manager")])
          ],
          1
        ),
        _c("div", { staticClass: "UserDetails-profile-details" }, [
          _c("div", { staticClass: "box-info" }, [
            _c("p", [_vm._v("Minutes spended")]),
            _c("span", { staticClass: "numbers-one" }, [_vm._v("243")])
          ]),
          _c("div", { staticClass: "box-info" }, [
            _c("p", [_vm._v("Meetings attended")]),
            _c("span", { staticClass: "numbers-two" }, [_vm._v("20")])
          ]),
          _c("div", { staticClass: "box-info" }, [
            _c("p", [_vm._v("Meetings called")]),
            _c("span", { staticClass: "numbers-three" }, [_vm._v("3")])
          ])
        ])
      ]),
      _c(
        "VBtn",
        {
          staticClass: "UserDetails-btn",
          attrs: { outlined: "" },
          on: {
            click: function($event) {
              return _vm.$store.commit("signOut")
            }
          }
        },
        [_vm._v("Log out")]
      ),
      _c("UploadAvatar", {
        attrs: { show: _vm.showModal },
        on: {
          "hide-modal": function($event) {
            _vm.showModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "User" },
    [
      _c("input", {
        staticClass: "User-btn-cover",
        attrs: { type: "file", name: "coverImage", id: "coverImage" },
        on: { change: _vm.validateUpload }
      }),
      _c(
        "label",
        { staticClass: "file-upload-input", attrs: { for: "coverImage" } },
        [_vm._v("CHANGE COVER")]
      ),
      _c("div", { staticClass: "User-bg", style: _vm.coverImageUrl }),
      _c("UserDetails"),
      _c("UserPreferences")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
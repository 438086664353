import { render, staticRenderFns } from "./UserDetails.vue?vue&type=template&id=900f83ba&"
import script from "./UserDetails.vue?vue&type=script&lang=ts&"
export * from "./UserDetails.vue?vue&type=script&lang=ts&"
import style0 from "./UserDetails.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Apogea\\Projects\\bim-meet\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('900f83ba')) {
      api.createRecord('900f83ba', component.options)
    } else {
      api.reload('900f83ba', component.options)
    }
    module.hot.accept("./UserDetails.vue?vue&type=template&id=900f83ba&", function () {
      api.rerender('900f83ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/user/UserDetails.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Overlay",
    { attrs: { show: _vm.show } },
    [
      _c(
        "VCard",
        { staticClass: "UploadAvatar" },
        [
          _c("h1", { staticClass: "text-h6" }, [
            _vm._v("Select a picture for your profile")
          ]),
          _c(
            "div",
            { staticClass: "UploadAvatar-avatar" },
            [_c("UserAvatar", { attrs: { size: 200 } })],
            1
          ),
          _c("VFileInput", {
            attrs: {
              accept: "image/png, image/jpeg",
              placeholder: "Pick an avatar",
              "prepend-icon": "mdi-camera",
              label: "Avatar"
            },
            model: {
              value: _vm.file,
              callback: function($$v) {
                _vm.file = $$v
              },
              expression: "file"
            }
          }),
          _c("div", { staticClass: "error-message" }, [
            _vm._v(_vm._s(_vm.errorMessage))
          ]),
          _c(
            "div",
            { staticClass: "UploadAvatar-buttons" },
            [
              _c(
                "VBtn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("hide-modal")
                    }
                  }
                },
                [_vm._v("CANCEL")]
              ),
              _c(
                "VBtn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.validateUpload()
                    }
                  }
                },
                [_vm._v("UPLOAD")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("LoadingOverlay", { attrs: { loading: _vm.loading } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
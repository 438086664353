var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VCard",
    { staticClass: "preferences" },
    [
      _c(
        "div",
        { staticClass: "preferences-nav" },
        [
          _c(
            "VTabs",
            {
              attrs: { "align-with-title": "" },
              model: {
                value: _vm.currentItem,
                callback: function($$v) {
                  _vm.currentItem = $$v
                },
                expression: "currentItem"
              }
            },
            _vm._l(_vm.tabItems, function(item) {
              return _c("VTab", { key: item, attrs: { href: "#" + item } }, [
                _vm._v(" " + _vm._s(item) + " ")
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(_vm.currentItem, { tag: "Component" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
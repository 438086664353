import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import UserPreferences from '@/components/user/UserPreferences.vue';
import UserDetails from '@/components/user/UserDetails.vue';
import store from '@/store';
var User = (function (_super) {
    __extends(User, _super);
    function User() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.coverImg = null;
        _this.errorMessage = '';
        _this.loading = false;
        return _this;
    }
    User.prototype.uploadCoverPicture = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, uid, storageRef, coverImg, data, photoURL, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this, uid = _a.uid, storageRef = _a.storageRef, coverImg = _a.coverImg;
                        console.log('uploadAvatar -> this.file', coverImg);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, 5, 6]);
                        this.loading = true;
                        return [4, storageRef().put(coverImg)];
                    case 2:
                        data = _b.sent();
                        return [4, storageRef().getDownloadURL()];
                    case 3:
                        photoURL = _b.sent();
                        store.dispatch('updateCoverPicture', { uid: uid, coverImg: photoURL });
                        console.log(data);
                        return [3, 6];
                    case 4:
                        error_1 = _b.sent();
                        console.log(error_1);
                        this.errorMessage = 'Not authorized';
                        return [3, 6];
                    case 5:
                        this.loading = false;
                        return [7];
                    case 6: return [2];
                }
            });
        });
    };
    User.prototype.storageRef = function () {
        return this.$storage.ref("/users/" + this.uid + "/" + this.fileName);
    };
    User.prototype.validateUpload = function (_a) {
        var files = _a.target.files;
        this.coverImg = files[0];
        var coverImg = this.coverImg;
        if (!coverImg)
            return (this.errorMessage = 'You must provide a file.');
        if (coverImg.type !== 'image/jpeg' && coverImg.type !== 'image/png') {
            return (this.errorMessage = 'You must provide a valid jpg/png file.');
        }
        this.uploadCoverPicture();
    };
    Object.defineProperty(User.prototype, "fileName", {
        get: function () {
            var _a;
            var coverImg = this.coverImg;
            return coverImg ? "coverPicture." + ((_a = coverImg === null || coverImg === void 0 ? void 0 : coverImg.name) === null || _a === void 0 ? void 0 : _a.substr(-3).toLowerCase()) : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(User.prototype, "uid", {
        get: function () {
            return store.state.user.uid || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(User.prototype, "coverImageUrl", {
        get: function () {
            var url = store.state.user.coverImg || 'https://i.imgur.com/2J0Th7R.png';
            return {
                background: "url('" + url + "')",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundPosition: 'center'
            };
        },
        enumerable: false,
        configurable: true
    });
    User = __decorate([
        Component({
            components: {
                UserPreferences: UserPreferences,
                UserDetails: UserDetails
            }
        })
    ], User);
    return User;
}(Vue));
export default User;

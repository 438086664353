import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { tabItems, importTabComponents } from './UserPreferences/tabItems';
var UserPreferences = (function (_super) {
    __extends(UserPreferences, _super);
    function UserPreferences() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tabItems = tabItems;
        return _this;
    }
    Object.defineProperty(UserPreferences.prototype, "currentItem", {
        get: function () {
            return this.$store.state.tabs.currentItem;
        },
        set: function (newTab) {
            this.$store.commit('setActiveTab', newTab);
            this.$router.replace({
                params: {
                    tab: newTab
                }
            });
        },
        enumerable: false,
        configurable: true
    });
    UserPreferences.prototype.mounted = function () {
        this.$store.commit('setActiveTab', this.$route.params.tab);
    };
    UserPreferences = __decorate([
        Component({
            components: importTabComponents()
        })
    ], UserPreferences);
    return UserPreferences;
}(Vue));
export default UserPreferences;
